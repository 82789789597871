.table {
  border-collapse: collapse;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0);
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}
.table > thead > tr > th,
.table > tbody > tr > td {
  padding: 12px 8px;
  vertical-align: middle;
  border-color: #e3e3e3;
}
.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  padding-bottom: 5px;
}
.table > tbody > tr {
  position: relative;
  font-size: 14px !important;
}
.table thead th {
  border-bottom: none !important;
  border-top: none !important;
}

.table > :not(:first-child) {
  border-top: 2px solid #43a047;
}

.table-card {
  height: 100%;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #43a047;
  border-color: #43a047;
}

.page-link {
  position: relative;
  display: block;
  color: #43a047;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-link:hover {
  color: #43a047;
}

.page-link:focus {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09),
    0 2px 3px -1px rgba(0, 0, 0, 0.07);
}

/* Dropdown */

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: rgb(67, 160, 71);
}

.navbar .navbar-brand {
  font-weight: 400;
  margin: 5px 0px;
  font-size: 20px;
  color: #888888;
}