*,
:after,
:before {
  box-sizing: border-box;
}
h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #344767;
}
h2 {
  font-size: calc(1.35rem + 1.2vw);
}
a,
a:hover {
  text-decoration: none;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  text-transform: none;
}
[type="button"],
button {
  -webkit-appearance: button;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.bg-gradient-primary {
  background-image: linear-gradient(195deg, #ec407a, #d81b60);
}
.bg-gradient-success {
  background-image: linear-gradient(195deg, #66bb6a, #43a047);
}
.bg-gradient-info {
  background-image: linear-gradient(195deg, #49a3f1, #1a73e8);
}
.bg-gradient-warning {
  background-image: linear-gradient(195deg, #ffa726, #fb8c00);
}
.bg-gradient-danger {
  background-image: linear-gradient(195deg, #ef5350, #e53935);
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h2 {
  font-size: 2.25rem;
  line-height: 1.3;
  letter-spacing: 0.05rem;
}
@media (max-width: 575.98px) {
  h2 {
    font-size: calc(1.35rem + 1.2vw);
  }
}
h2 {
  font-weight: 600;
  font-family: Roboto Slab, sans-serif;
}
h2 {
  letter-spacing: -0.05rem;
}
a {
  letter-spacing: 0;
  color: #344767;
}
.fc {
  display: flex;
  flex-direction: column;
  font-size: 1em;
}
.fc,
.fc *,
.fc :after,
.fc :before {
  box-sizing: border-box;
}
.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}
.fc th {
  text-align: center;
}
.fc td,
.fc th {
  vertical-align: top;
  padding: 0;
}
.fc-direction-ltr {
  direction: ltr;
  text-align: left;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
}
.fc-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: fcicons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fc-icon-chevron-left:before {
  content: "\e900";
}
.fc-icon-chevron-right:before {
  content: "\e901";
}
.fc .fc-button {
  border-radius: 0;
  overflow: visible;
  text-transform: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.fc .fc-button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.fc .fc-button {
  -webkit-appearance: button;
}
.fc .fc-button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.fc .fc-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4em 0.65em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
}
.fc .fc-button:hover {
  text-decoration: none;
}
.fc .fc-button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}
.fc .fc-button:disabled {
  opacity: 0.65;
}
.fc .fc-button-primary {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2c3e50;
  background-color: var(--fc-button-bg-color, #2c3e50);
  border-color: #2c3e50;
  border-color: var(--fc-button-border-color, #2c3e50);
}
.fc .fc-button-primary:hover {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #1e2b37;
  background-color: var(--fc-button-hover-bg-color, #1e2b37);
  border-color: #1a252f;
  border-color: var(--fc-button-hover-border-color, #1a252f);
}
.fc .fc-button-primary:disabled {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #2c3e50;
  background-color: var(--fc-button-bg-color, #2c3e50);
  border-color: #2c3e50;
  border-color: var(--fc-button-border-color, #2c3e50);
}
.fc .fc-button .fc-icon {
  vertical-align: middle;
  font-size: 1.5em;
}
.fc .fc-button-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.fc .fc-button-group > .fc-button {
  position: relative;
  flex: 1 1 auto;
}
.fc .fc-button-group > .fc-button:active,
.fc .fc-button-group > .fc-button:focus,
.fc .fc-button-group > .fc-button:hover,
.fc .fc-toolbar-chunk > .fc-today-button:active,
.fc .fc-toolbar-chunk > .fc-today-button:focus,
.fc .fc-toolbar-chunk > .fc-today-button:hover {
  background-color: rgb(67, 160, 71);
  color: white;
  z-index: 1;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc .fc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
}
.fc .fc-toolbar-title {
  font-size: 1.75em;
  margin: 0;
}
.fc .fc-highlight {
  background: rgba(188, 232, 241, 0.3);
  background: var(--fc-highlight-color, rgba(188, 232, 241, 0.3));
}
a.fc-event,
a.fc-event:hover {
  text-decoration: none;
}
.fc-event.fc-event-draggable {
  cursor: pointer;
}
.fc-event .fc-event-main {
  position: relative;
  z-index: 2;
}
.fc-event .fc-event-resizer {
  display: none;
  position: absolute;
  z-index: 4;
}
.fc-event:hover .fc-event-resizer {
  display: block;
}
.fc-h-event {
  display: block;
  border: 1px solid #3788d8;
  border: 1px solid var(--fc-event-border-color, #3788d8);
  background-color: #3788d8;
  background-color: var(--fc-event-bg-color, #3788d8);
}
.fc-h-event .fc-event-main {
  color: #fff;
  color: var(--fc-event-text-color, #fff);
}
.fc-h-event .fc-event-main-frame {
  display: flex;
}
.fc-h-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}
.fc-h-event .fc-event-title {
  display: inline-block;
  vertical-align: top;
  left: 0;
  right: 0;
  max-width: 100%;
  overflow: hidden;
}
.fc-h-event:not(.fc-event-selected) .fc-event-resizer {
  top: 0;
  bottom: 0;
  width: 8px;
  width: var(--fc-event-resizer-thickness, 8px);
}
.fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end {
  cursor: e-resize;
  right: -4px;
  right: calc(var(--fc-event-resizer-thickness, 8px) / -2);
}
.fc-daygrid-day-events:after,
.fc-daygrid-day-events:before,
.fc-daygrid-day-frame:after,
.fc-daygrid-day-frame:before,
.fc-daygrid-event-harness:after,
.fc-daygrid-event-harness:before {
  content: "";
  clear: both;
  display: table;
}
.fc .fc-daygrid-body {
  position: relative;
  z-index: 1;
}
.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 100%;
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
}
.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 0.3;
}
.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
}
.fc .fc-daygrid-day-events {
  margin-top: 1px;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: relative;
  min-height: 2em;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 1em;
}
.fc .fc-daygrid-event-harness {
  position: relative;
}
.fc .fc-daygrid-event-harness-abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc .fc-daygrid-bg-harness {
  position: absolute;
  top: 0;
  bottom: 0;
}
.fc .fc-daygrid-day-bg .fc-highlight {
  z-index: 3;
}
.fc .fc-daygrid-event {
  z-index: 6;
  margin-top: 1px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  margin-left: 2px;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-right: 2px;
}
.fc-daygrid-event {
  position: relative;
  white-space: nowrap;
  border-radius: 3px;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
}
.fc-daygrid-block-event .fc-event-title {
  padding: 1px;
}
.fc-scrollgrid,
.fc-theme-standard .fc-scrollgrid {
  border: none;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #f0f2f5;
}
.fc-theme-standard thead tr th {
  border-right: none;
  border-left: none;
}
.fc-theme-standard td:last-child {
  border-right: none;
}
.fc-theme-standard tr:last-child td {
  border-bottom: none;
}
.fc-h-event {
  border: none;
}
.fc-daygrid-block-event .fc-event-title {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25;
  padding: 0.2rem 0.3rem;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0;
}
.fc .fc-col-header-cell-cushion {
  font-size: 0.875rem;
  font-weight: 600;
  color: #505050;
}
.fc .fc-daygrid-day-number {
  color: #000000;
  font-size: 0.875rem;
  font-weight: 300;
  width: 100%;
  text-align: center;
}
.fc .fc-toolbar-title {
  font-size: 1.98rem;
}
.fc .fc-button {
  padding-top: 0.25rem;
  box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
    0 2px 4px -1px rgba(0, 0, 0, 0.07);
  transition: all 0.15s ease-in;
}
.fc .fc-button,
.fc .fc-button .fc-icon {
  font-size: 0.875rem;
}
.fc .fc-button-primary {
  background-color: rgb(67, 160, 71);
  border-color: rgb(67, 160, 71);
}
.fc .fc-button-primary:focus,
.fc .fc-button-primary:hover {
  transform: none;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.09),
    0 2px 3px -1px rgba(0, 0, 0, 0.07);
  background-color: rgb(67, 160, 71);
  border-color: rgb(67, 160, 71);
}
.fc-event.fc-daygrid-event {
  border-radius: 0.35rem;
}

.fc-event-time,
.fc-event-title {
  padding: 1px;
  white-space:nowrap;
}

.calendar-header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.calendar-header-title {
  font-size: 1.3em;
}

.yellow-dot, .blue-dot, .green-dot, .red-dot, .gray-dot, .orange-dot {
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border-radius: 4px;
  border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
}

.yellow-dot {
  border: 4px solid #fff200;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #fff200);
}

.blue-dot {
  border: 4px solid #0080ff;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #0080ff);
}

.green-dot {
  border: 4px solid #15ff00;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #15ff00);
}

.red-dot {
  border: 4px solid #ff0000;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #ff0000);
}

.orange-dot {
  border: 4px solid #ff9500;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #ff9500);
}

.gray-dot {
  border: 4px solid #4a4a4a;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, #4a4a4a);
}