body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 300;
  margin: 30px 0 15px;
}

h1,
.h1 {
  font-size: 52px;
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 28px;
  margin: 20px 0 10px;
}

h4,
.h4 {
  font-size: 22px;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 16px;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: #9a9a9a;
  font-weight: 300;
  line-height: 1.5;
}

h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 60%;
}

h1 .subtitle {
  display: block;
  margin: 0 0 30px;
}

.text-info,
.text-info:hover {
  color: #1dc7ea !important;
}

.text-success,
.text-success:hover {
  color: #87cb16 !important;
}

.text-warning,
.text-warning:hover {
  color: #ff9500 !important;
}

.text-danger,
.text-danger:hover {
  color: #ff4a55 !important;
}

.margin-top-3px {
  margin-top: 3px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-right-10px {
  margin-right: 10px;
}

.margin-right-5px {
  margin-right: 5px;
}

.margin-left-5px {
  margin-left: 5px;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-center {
  align-items: center;
}

.align-self-end {
  align-self: flex-end;
}

.modal-width {
  max-width: 750px !important;
}

.modal-time-text {
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
}

.strikethrough {
  text-decoration: line-through;
}

.width-100pc {
  width: 100%;
}

.heigth-150px {
  height: 150px;
}

/* Sidebar */

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar,
body > .navbar-collapse {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
}

.sidebar .sidebar-wrapper,
body > .navbar-collapse .sidebar-wrapper {
  position: relative;
  max-height: calc(100vh - 75px);
  min-height: 100%;
  overflow: auto;
  width: 200px;
  z-index: 4;
  padding-bottom: 100px;
}

.sidebar .logo,
body > .navbar-collapse .logo {
  padding: 10px 15px 9px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  z-index: 4;
}

.sidebar .nav,
body > .navbar-collapse .nav {
  margin-top: 20px;
  float: none;
  display: block;
}

.sidebar .nav li .nav-link,
body > .navbar-collapse .nav li .nav-link {
  color: #ffffff;
  margin: 5px 15px;
  opacity: 0.86;
  border-radius: 4px;
  display: block;
  padding: 10px 15px;
}

.sidebar .nav li .nav-link p,
body > .navbar-collapse .nav li .nav-link p {
  margin: 0;
  line-height: 31px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-flex;
}

.sidebar .nav li .nav-link i,
body > .navbar-collapse .nav li .nav-link i {
  font-size: 28px;
  margin-right: 15px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  float: left;
}

.sidebar .nav li:hover .nav-link,
body > .navbar-collapse .nav li:hover .nav-link {
  background: rgba(255, 255, 255, 0.13);
  opacity: 1;
}

.sidebar .nav li.active .nav-link,
body > .navbar-collapse .nav li.active .nav-link {
  color: #ffffff;
  opacity: 1;
  background: rgba(255, 255, 255, 0.23);
}

.sidebar .logo .simple-text,
body > .navbar-collapse .logo .simple-text {
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
}

.sidebar:after,
.sidebar:before,
body > .navbar-collapse:after,
body > .navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: calc(100% - 200px);
  min-height: 100%;
}

.main-panel > .content {
  padding: 15px 15px;
  height: 100%;
}

.sidebar,
.main-panel {
  overflow: auto;
  max-height: 100%;
  height: 100%;
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}

.navbar {
  border: 0;
  font-size: 16px;
  border-radius: 0;
  min-height: 50px;
  background-color: rgba(255, 255, 255, 0.96);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.navbar .navbar-brand {
  font-weight: 400;
  margin: 5px 0px;
  font-size: 20px;
  color: #888888;
}

.sidebar {
  background: linear-gradient(45deg, rgb(102, 187, 106), rgb(67, 160, 71));
  overflow: hidden;
}

.sidebar .logo-img img {
  width: 50%;
}

/* Footer */

.footer-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-panel > .footer {
  border-top: 1px solid #e7e7e7;
}

.footer {
  background-color: #ffffff;
}

.footer nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.footer nav > ul a:not(.btn) {
  color: #9a9a9a;
  display: block;
  margin-bottom: 3px;
}

.footer .copyright {
  color: #777777;
  padding: 10px 15px;
  margin: 10px 3px;
  line-height: 20px;
  font-size: 14px;
}

.footer:not(.footer-big) nav > ul a {
  padding: 10px 0px;
  margin: 10px 10px 10px 0px;
}

/* Card */

.card, .overview-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 25px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  width: 95%;
}

.overview-card {
  width: 50%;
}

.content {
  display: flex;
  justify-content: center;
}

/* Spinner */

.loading {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}

.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgb(67, 160, 71);
  animation: spin 1.1s infinite linear;
}

.event-counter {
  color: rgb(67, 160, 71)
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Login Page */

.login-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 3%;
}

.login-logo {
  width: 40%;
}

.login-logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.login-content {
  background: linear-gradient(45deg, rgb(102, 187, 106), rgb(67, 160, 71));
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  padding: 20px;
  width: 20%;
}

/* Google Button */

.google-button {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.google-icon {
  background: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 2px;
}

.google-button-text {
  padding: 10px 10px 10px 0px;
  font-weight: 500;
}

.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.action-icon {
  color: #2f4f4f;
  cursor: pointer;
}

.icon-large {
  font-size: 15px;
}

/* Section Divider */

.section-divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #2f4f4f;
  line-height: 0.1em;
  margin: 20px 0 20px;
}

.section-divider > span {
  background: #fff;
  color: #2f4f4f;
  padding: 0 10px;
}

.export-modal-body-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
}

.export-modal-body-container > div {
  width: 70%;
}

.export-modal-body-container button {
  width: 100%;
}

/* Phone input */

.react-tel-input .form-control {
  height: 38px;
  width: 100%;
}

/* Toggle buttons */

.toggle-button {
  background-color: rgb(67, 160, 71) !important;
  border-color: rgb(67, 160, 71) !important;
}

.btn-check:checked + .btn-primary {
  background-color: rgb(15, 93, 19) !important;
  border-color:  rgb(15, 93, 19)  !important;
}